import { useState } from 'react';
import { Grid } from '@mui/material';
import { AdminNav } from './AdminNav';

export default function Admin() {
  const [activeComponent, setActiveComponent] = useState<string>('');

  const handleClick = (componentName: string) => {

    setActiveComponent(componentName);
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={2} sm={2}>
        <AdminNav/>
      </Grid>
      <Grid item xs={10} sm={10}>
        {
          
        }
      </Grid>
    </Grid>
  );
}