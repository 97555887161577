import { Grid, Card, CardContent, Typography } from '@mui/material';
import { Tire } from '../interfaces/Tire';

interface TireListProps {
    tires: Tire[];
}

function getTirePosition(axle: number, side: number) {
    if (axle === 1 && side === 0) return 'Foran venstre';
    if (axle === 1 && side === 1) return 'Foran høyre';
    if (axle === 2 && side === 0) return 'Bak venstre';
    if (axle === 2 && side === 1) return 'Bak høyre';
    return 'Unknown';
};

const TireList: React.FC<TireListProps> = ({ tires }) => {
    return (
        <Grid container spacing={2}>
            {tires.map((tire, index) => (
                <Grid item xs={12} sm={6} key={index}>
                    <Card>
                        <CardContent>
                            <Typography variant="body1" component="div">
                                {getTirePosition(tire.axle, tire.side)}
                            </Typography>
                            <Typography variant="caption" color="text.secondary">
                                {tire.make} {tire.model} ({tire.width}/{tire.heigth}R{tire.rimDiameter} - {tire.loadClass}{tire.speedCode})
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Produsert (uke/år): {tire.productionTimestamp}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Mønsterdybde (mm): Utside {tire.threadDepthOutside} - Senter {tire.threadDepthMiddle} - Innside {tire.threadDepthInside}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Piggdekk: {tire.isStudded ? 'Ja' : 'Nei'}
                            </Typography>
                            {tire.isStudded && (
                                <Typography variant="body2" color="text.secondary">
                                    Piggslipp: {tire.missingStudCount}
                                </Typography>
                            )}
                            <Typography variant="body2" color="text.secondary">
                                Skev slitasje: {tire.isWornUnevenly ? 'Ja' : 'Nei'}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default TireList;