import { Box, CircularProgress } from "@mui/material";
import React from "react";

export const PageLoader: React.FC = () => {
 
  return (
    <div className="loader" style={{ position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <CircularProgress color='success' size={48} />
      </Box>
    </div>
  );
};