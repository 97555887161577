import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { AdminNav } from "./admin/AdminNav";

export const CallbackPage: React.FC = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      
        <div>
          <h1 id="page-title">
            Error
          </h1>
          <div>
            <p>
              <span>{error.message}</span>
            </p>
          </div>
        </div>
    );
  }

  return (
    <AdminNav/>
  );
};
