import React, { useState, useEffect } from 'react';
import {
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Typography, Grid, LinearProgress, Box, Paper, Button, Alert
} from '@mui/material';

import axios from 'axios';
import { AdminNav } from '../AdminNav';
import { useTheme } from '@mui/material/styles';
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse';
import { apiUrl } from '../../config';
import ErrorMessage from '../../shared-components/ErrorMessage';

function AppointmentConfiguration() {
  const theme = useTheme();
  
  return (
    <Grid container justifyContent="flex">
      <Grid item xs={2}>
        <AdminNav />
      </Grid>
      <Grid item xs={10}>
        <Typography variant='h5' color="text.secondary">Timebestilling oppsett</Typography>
        {/* <Typography variant='body1' color="text.primary">Velg sesong for å filtrere oversikten</Typography> */}
        <div>
          {ErrorMessage({ message: 'Ikke implementert enda' })}
        </div>

      </Grid>
    </Grid>
  );
}

export default AppointmentConfiguration;