import React, { useState, useEffect } from 'react'
import {
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Typography,
  Grid, TextField, LinearProgress, Box
} from '@mui/material'
import axios from 'axios'
import { AdminNav } from '../AdminNav'
import { useTheme } from '@mui/material/styles'
import TireRatingTable from './TireRatingTable'
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse'
import { apiUrl } from '../../config'
import ErrorMessage from '../../shared-components/ErrorMessage'

function TireRatingOverview() {
  const [seasons, setSeasons] = useState<string[]>([])
  const [evaluations, setEvaluations] = useState<EvaluationsApiResponse[]>([])
  const theme = useTheme()
  const [searchInput, setSearchInput] = useState('')
  const [selectedSeason, setSelectedSeason] = useState(() => {
    const savedSeason = localStorage.getItem('selectedSeason')
    return savedSeason ? savedSeason : ''
  })

  const [error, setError] = useState<string | null>(null) // State for error message

  const handleDeleteEvaluation = (id: number | null) => {
    setEvaluations(prevEvaluations => prevEvaluations.filter(evaluation => evaluation.id !== id))
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  const searchEvaluations = (searchTerm: string, selectedSeason: string) => {
    axios.get(`${apiUrl}/admin/evaluations/search/${selectedSeason}/${searchTerm}`)
      .then(response => setEvaluations(response.data))
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setError(error.response.data)
        }
        else {
          setError(error.message)
          console.error(error)
        }
      })
  }

  const evaluationsForSeason = (season: string) => {
    setIsSearching(true)
    axios.get(`${apiUrl}/admin/evaluations/season/${season}`)
      .then(response => setEvaluations(response.data))
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setError(error.response.data)
        }
        else {
          setError(error.message)
          console.error(error)
        }
      }).finally(() => setIsSearching(false))
  }

  useEffect(() => {
    if (selectedSeason) {
      evaluationsForSeason(selectedSeason)
    }
  }, [selectedSeason])

  useEffect(() => {
    if (searchInput.length >= 3) {
      const timeoutId = setTimeout(() => {
        searchEvaluations(searchInput, selectedSeason)
      }, 500) // 500ms delay

      return () => clearTimeout(timeoutId) // clear the timeout if the input changes before the delay is over
    }
    else if (searchInput.length === 0 && selectedSeason) {
      evaluationsForSeason(selectedSeason)
    }
  }, [searchInput, selectedSeason])

  const [isSearching, setIsSearching] = useState(false)

  useEffect(() => {
    setIsSearching(true)
    axios.get(`${apiUrl}/admin/evaluations/seasons/`)
      .then(response => {
        setSeasons(response.data)
        setIsSearching(false)
      })
      .catch(error => {
        setIsSearching(false)

        if (error.response.data) {
          console.error(error.response.data)
          setError(error.response.data)
        }
        else {
          setError(error.message)
          console.error(error)
        }
      })
  }, [])

  useEffect(() => {
    localStorage.setItem('selectedSeason', selectedSeason)
  }, [selectedSeason])
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedSeason(event.target.value as string)
  }

  return (
    <Grid container justifyContent="flex">
      <Grid item xs={2}>
        <AdminNav />
      </Grid>
      <Grid item xs={10}>
        <Typography variant='h5' color="text.secondary">Dekkvurderinger</Typography>
        <Typography variant='body1' color="text.primary">Velg sesong og bruk filter for å filtrere resultatet</Typography>
        <div>
          {error && <ErrorMessage message={error} />}
        </div>
        <Grid container item direction="row" justifyContent="space-between">
          <Grid item xs={10} sm={10} md={5} mx={1} my={1}>
            <Box position="relative" display="inline-flex" width="100%">
              <FormControl fullWidth>
                <InputLabel id="season-label">Sesong</InputLabel>
                <Select
                  labelId="season-label"
                  value={selectedSeason}
                  onChange={handleChange}
                  title='Sesong'
                >
                  {seasons.map((season, index) => (
                    <MenuItem key={index} value={season}>{season}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

          </Grid>
          <Grid item xs={10} sm={10} md={6} my={1}>
            <FormControl fullWidth>
              <TextField
                id="search-input"
                label="Filter (minst 3 tegn)"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} padding={1}>
          {isSearching && (
            <Box position="absolute" bottom={0} left={0} right={0}>
              <LinearProgress color='warning' />
            </Box>
          )}
          <TireRatingTable evaluations={evaluations} searchInput={searchInput} onDeleteEvaluation={handleDeleteEvaluation} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TireRatingOverview