import React from 'react';
import { Card, CardContent, Typography, CardActions, Button } from '@mui/material';

interface ActionCardProps {
  title: string;
  description: string;
  onClick: () => void;
}

const ActionCard: React.FC<ActionCardProps> = ({ title, description, onClick }) => {
  return (
    <Card sx={{ minWidth: 200 }}>
      <CardContent>
        <Typography variant='h5' color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2">
        {description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={onClick} size="small">{title}</Button>
      </CardActions>
    </Card>
  );
}

export default ActionCard;