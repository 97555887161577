import React, { useEffect } from 'react';
import './App.css';
import {auth0redirect} from './config'
import Grid from '@mui/material/Unstable_Grid2';
import MainLayout from './MainLayout';
import ActionCard from './ActionCard';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AdminLogin from './admin/AdminLogin';
import TireRating from './customer/TireRating';
import TireRatingLogin from './customer/TireRatingLogin';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Admin from './admin/Admin';
import TireRatingOverview from './admin/tirerating/TireRatingOverview';
import TireRatingDetails from './admin/tirerating/TireRatingDetails';
import TireRatingAdd from './admin/tirerating/TireRatingAdd';
import TireRatingSearch from './admin/tirerating/TireRatingSearch';
import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { AuthenticationGuard } from './shared-components/authentication-guard';
import { CallbackPage } from './callback';
import TireRatingSms from './admin/tirerating/TireRatingSms';
import AppointmentOverview from './admin/appointments/AppointmentOverview';
import AppointmentConfiguration from './admin/appointments/AppointmentConfiguration';

function App() {
  const navigate = useNavigate();

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );
  useEffect(() => {
    console.log('Redirect:' + auth0redirect);
  }, []); 

  return (
    <Auth0ProviderWithNavigate>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainLayout>
          <Routes>
            <Route path="/" element={
              <Grid container spacing={2} justifyContent="center">
                <Grid xs={12} sm={3}>
                  <ActionCard
                    description='Logg inn for å lese vurdering av dine dekk. Krever innlogging med telefonnummer og bilens registreringsnummer'
                    onClick={() => navigate('/dekkvurdering')}
                    title='Dekkvurdering'
                  />
                </Grid>
                <Grid xs={12} sm={3}>
                  <ActionCard
                    description='Adminstrator-innlogging'
                    onClick={() => navigate('/admin')}
                    title='Administrasjon'
                  />
                </Grid>
              </Grid>
            } />
            <Route path="/callback" element={<CallbackPage />} />
            <Route path="/admin" element={<AdminLogin />} />
            <Route path="/admin/auth" element={<AuthenticationGuard component={Admin} />} />
            <Route path="/admin/auth/TireRating/Overview" element={<AuthenticationGuard component={TireRatingOverview} />} />
            <Route path="/admin/auth/TireRating/Search" element={<AuthenticationGuard component={TireRatingSearch} />} />
            <Route path="/admin/auth/TireRating/Add" element={<AuthenticationGuard component={TireRatingAdd} />} />
            <Route path="/admin/auth/TireRating/Sms" element={<AuthenticationGuard component={TireRatingSms} />} />
            <Route path="/admin/auth/tirerating/:evaluationId" element={<AuthenticationGuard component={TireRatingDetails} />} />
            <Route path="/admin/auth/appointments/overview" element={<AuthenticationGuard component={AppointmentOverview} />} />
            <Route path="/admin/auth/appointments/config" element={<AuthenticationGuard component={AppointmentConfiguration} />} />
            <Route path="/dekkvurdering" element={<TireRatingLogin />} />
            <Route path="/dekkvurdering/:telefonnummer/:registreringsnummer" element={<TireRating />} />
          </Routes>
        </MainLayout>
      </ThemeProvider>
    </Auth0ProviderWithNavigate>
  );
}

export default App;
