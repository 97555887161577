import { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Link, Card, CardContent, CardHeader, Avatar, useTheme
} from '@mui/material';
import axios from 'axios';
import { Email, Map, Phone, ThumbDownTwoTone, ThumbUpTwoTone } from '@mui/icons-material';
import { AdminNav } from '../AdminNav';
import { useParams } from 'react-router-dom';
import InfoRow from '../../shared-components/InfoRow';
import TireList from '../../shared-components/TireList';
import { apiUrl } from '../../config';
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse';

function TireRatingDetails() {
  const { evaluationId } = useParams<{ evaluationId: string }>();
  const [evaluation, setEvaluation] = useState<EvaluationsApiResponse | null>(null);
  const theme = useTheme();
  useEffect(() => {
    if (evaluationId) {
      axios.get<EvaluationsApiResponse>(`${apiUrl}/admin/evaluations/${evaluationId}`)
        .then(response => setEvaluation(response.data))
        .catch(error => console.error(error));
    }
  }, [evaluationId]);

  return (
    <Grid container justifyContent="flex">
      <Grid item md={2} sm={3} xs={12}>
        <AdminNav />
      </Grid>
      <Grid item md={10} sm={9} xs={12}>
        {evaluation === null ? (
          <Typography variant='h5' color="text.secondary">
            Laster inn dekkvurdering...
          </Typography>
        ) : (
          <Grid>
            <Grid>
              <Typography variant='h5' color="text.secondary">Dekkvurdering for {evaluation.vehicle.make} {evaluation.vehicle.model} {evaluation.vehicle.registrationNumber} [{evaluation.vehicle.location}]</Typography>
            </Grid>
            <Grid margin={1}>
              <Grid item xs={12} padding={1}>
                <Card variant="outlined">
                  <CardHeader title='Kunde' />
                  <CardContent>
                    <Typography variant='h6' color="text.primary">{evaluation.vehicle.customer.name}</Typography>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Phone />
                      </Grid>
                      <Grid item marginLeft={2}>
                        <Link href={`tel:${evaluation.vehicle.customer.phone}`}>{evaluation.vehicle.customer.phone}</Link>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Email />
                      </Grid>
                      <Grid item marginLeft={2}>
                        <Link href={`mailto:${evaluation.vehicle.customer.email}`}>{evaluation.vehicle.customer.email}</Link>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                      <Grid item>
                        <Map />
                      </Grid>
                      <Grid item marginLeft={2}>
                        <Typography variant='body2'>{evaluation.vehicle.customer.address}</Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} padding={1}>
                <Card variant="outlined" >
                  <CardHeader title='Kjøretøy' />
                  <CardContent>
                    <InfoRow label="Merke" value={evaluation.vehicle.make} />
                    <InfoRow label="Modell" value={evaluation.vehicle.model} />
                    <InfoRow label="Regnummer" value={evaluation.vehicle.registrationNumber} />
                    <InfoRow label="Plassering" value={evaluation.vehicle.location} />
                  </CardContent>

                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar style={{
                          backgroundColor:
                            evaluation.rating === 'bra' ? theme.palette.success.main :
                              evaluation.rating === 'middels' ? theme.palette.warning.main :
                                theme.palette.error.main
                        }}>
                          {evaluation.rating === 'bra' || evaluation.rating === 'middels' ? <ThumbUpTwoTone /> : <ThumbDownTwoTone />}
                        </Avatar>
                      }
                      title="Dekkvurdering"
                      titleTypographyProps={{ variant: 'h4', color: "text.secondary" }}
                    />
                    <CardContent>
                      <InfoRow label='Gjennomført av' value={evaluation.performedBy} />
                      <InfoRow label='Dato' value={new Date(evaluation.performedDate).toLocaleDateString('nb-NO')} />
                      <InfoRow label='Kommentar' value={evaluation.note} />
                      <TireList tires={evaluation.tires} />
                    </CardContent>
                  </Card>
                </Card>
              </Grid>
            </Grid>
          </Grid>

        )}
      </Grid>
    </Grid>
  );
}

export default TireRatingDetails;