import { ArrowCircleDownTwoTone, ArrowCircleRightTwoTone, ArrowDropDownCircleRounded, CommentBankOutlined, Delete, DeleteForever, Label, RecyclingOutlined, RecyclingTwoTone, SaveTwoTone, SearchTwoTone, ThumbDownTwoTone, ThumbUpSharp, ThumbUpTwoTone, ThumbsUpDownTwoTone } from '@mui/icons-material';
import { Alert, AutocompleteRenderInputParams, Box, ButtonGroup, Checkbox, CircularProgress, FormControl, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableRow, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import axios from 'axios';
import { FastField, Field, Form, Formik, FormikHelpers, useFormik, useFormikContext } from 'formik';
import { Autocomplete, CheckboxWithLabel, Select, Switch, TextField } from 'formik-mui';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiUrl } from '../../config';
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse';
import { AdminNav } from '../AdminNav';
import { green } from '@mui/material/colors';
import { useAuth0 } from "@auth0/auth0-react";

enum TirePosition {
    FrontLeft,
    FrontRight,
    RearLeft,
    RearRight,
    Front,
    Rear,
    All
}

const StyledBox = styled(Box)({
    borderRadius: '15px',
    border: '1px solid #d3d3d3',
    padding: '5px', // Add some margin to prevent the border from touching adjacent cells
});

export default function TireRatingAdd() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const copyFrom = queryParams.get('copyFrom');
    const [evaluation, setEvaluation] = React.useState<EvaluationsApiResponse>();
    const [checked, setChecked] = React.useState([0]);
    const navigate = useNavigate();

    const { user } = useAuth0();
    interface Props {
        from: TirePosition;
        to: TirePosition;
    }

    const CopyTire: React.FC<Props> = ({ from, to }) => {
        const formik = useFormikContext<EvaluationsApiResponse>();
        const handleClick = () => {

            if (from === TirePosition.Front && to === TirePosition.Rear) {
                setEvaluation(prevEvaluation => {
                    if (!prevEvaluation) {
                        return; // or return some default state
                    }

                    const newTires = formik.values.tires.map(tire => {
                        // If this is the target tire
                        if (tire.axle === 2) {
                            const sourceTire = formik.values.tires.find(t => t.axle === 1 && t.side === tire.side);
                            if (sourceTire) {
                                return { ...sourceTire, id: tire.id, axle: tire.axle, side: tire.side };
                            }
                        }
                        // Return a new tire object, even if it's the same as the original
                        return { ...tire };
                    });

                    const newEvaluation = { ...formik.values, tires: newTires };

                    // Call formik.setValues here, after the state has been updated
                    if (newEvaluation) {
                        //console.log('NEW', newEvaluation);
                        formik.setValues(newEvaluation);
                    }

                    return newEvaluation;
                });
            } else if (from === TirePosition.FrontLeft && to === TirePosition.FrontRight) {
                //console.log('Copy from front left to front right');
                setEvaluation(prevEvaluation => {
                    if (!prevEvaluation) {
                        return; // or return some default state
                    }

                    // Find the source tire
                    const sourceTire = formik.values.tires.find(tire => tire.axle === 1 && tire.side === 0);
                    if (!sourceTire) {
                        return prevEvaluation; // If there's no source tire, return the previous state
                    }

                    // Map over the tires
                    const newTires = formik.values.tires.map(tire => {
                        // If this is the target tire
                        if (tire.axle === 1 && tire.side === 1) {
                            // Return a new tire with the properties of the source tire, but keep the original id
                            return { ...sourceTire, id: tire.id, side: tire.side };
                        }

                        // If this is not the target tire, return it unchanged
                        return tire;
                    });

                    const newEvaluation = { ...formik.values, tires: newTires };

                    // Call formik.setValues here, after the state has been updated
                    if (newEvaluation) {
                        //console.log('NEW', newEvaluation);
                        formik.setValues(newEvaluation);
                    }

                    return newEvaluation;
                })
            } else if (from === TirePosition.RearLeft && to === TirePosition.RearRight) {
                //console.log('Copy from rear left to rear right');
                setEvaluation(prevEvaluation => {
                    if (!prevEvaluation) {
                        return; // or return some default state
                    }

                    // Find the source tire
                    const sourceTire = formik.values.tires.find(tire => tire.axle === 2 && tire.side === 0);
                    if (!sourceTire) {
                        return prevEvaluation; // If there's no source tire, return the previous state
                    }

                    // Map over the tires
                    const newTires = formik.values.tires.map(tire => {
                        // If this is the target tire
                        if (tire.axle === 2 && tire.side === 1) {
                            // Return a new tire with the properties of the source tire, but keep the original id
                            return { ...sourceTire, id: tire.id, side: tire.side };
                        }

                        // If this is not the target tire, return it unchanged
                        return tire;
                    });
                    const newEvaluation = { ...formik.values, tires: newTires };

                    // Call formik.setValues here, after the state has been updated
                    if (newEvaluation) {
                        //console.log('NEW', newEvaluation);
                        formik.setValues(newEvaluation);
                    }

                    return newEvaluation;
                });
            } else if (from === TirePosition.FrontLeft && to === TirePosition.All) {
                console.log('Copy from front left to all');
                setEvaluation(prevEvaluation => {
                    if (!prevEvaluation) {
                        return; // or return some default state
                    }

                    // Find the source tire
                    const sourceTire = formik.values.tires.find(tire => tire.axle === 1 && tire.side === 0);
                    if (!sourceTire) {
                        return prevEvaluation; // If there's no source tire, return the previous state
                    }

                    // Map over the tires
                    const newTires = formik.values.tires.map(tire => {
                        // If this is the target tire
                        if (!(tire.axle === 1 && tire.side === 0)) {
                            // Return a new tire with the properties of the source tire, but keep the original id
                            return { ...sourceTire, id: tire.id, axle: tire.axle, side: tire.side };
                        }

                        // If this is not the target tire, return it unchanged
                        return tire;
                    });

                    const newEvaluation = { ...formik.values, tires: newTires };

                    // Call formik.setValues here, after the state has been updated
                    if (newEvaluation) {
                        //console.log('NEW', newEvaluation);
                        formik.setValues(newEvaluation);
                    }

                    return newEvaluation;
                })
            }
        }

        if (from === TirePosition.Front && to === TirePosition.Rear) {
            return <Button
                onClick={handleClick}
                size='small'
                variant='outlined'
                startIcon={<ArrowCircleDownTwoTone />}>
                Bak
            </Button>
        } else if (from === TirePosition.FrontLeft && to === TirePosition.All) {
            return <Button
                onClick={handleClick}
                size='small'
                variant='outlined'
                startIcon={<RecyclingTwoTone />}>
                Alle
            </Button>;
        } else {
            return <Button
                onClick={handleClick}
                size='small'
                variant='outlined'
                startIcon={<ArrowCircleRightTwoTone />}>
                Høyre
            </Button>;
        }
    };
    
    const defaultValues: EvaluationsApiResponse = {
        id: null,
        vehicle: {
            registrationNumber: '',
            make: '',
            model: '',
            customer: {
                name: '',
                company: '',
                email: '',
                phone: '',
                id: null,
                address: ''
            },
            id: null,
            location: ''
        },
        season: '',
        performedBy: '',
        performedDate: new Date().toISOString(),
        note: '',
        smsLogEntries: [{ id: null, hasBeenSent: false, errorCode: '', errorDescription: '', phoneNumber: '', statusTimestamp: new Date(), sveveMessageId: ''}],
        tires: [
            {
                axle: 1,
                side: 0,
                id: null,
                make: '',
                model: '',
                width: 0,
                heigth: 0,
                rimDiameter: 0,
                loadClass: '',
                speedCode: '',
                productionTimestamp: '',
                isStudded: false,
                isTwin: false,
                threadDepthOutside: -1,
                threadDepthMiddle: -1,
                threadDepthInside: -1,
                missingStudCount: -1,
                isWornUnevenly: false,
                note: ''
            }, {
                axle: 1,
                side: 1,
                id: null,
                make: '',
                model: '',
                width: 0,
                heigth: 0,
                rimDiameter: 0,
                loadClass: '',
                speedCode: '',
                productionTimestamp: '',
                isStudded: false,
                isTwin: false,
                threadDepthOutside: -1,
                threadDepthMiddle: -1,
                threadDepthInside: -1,
                missingStudCount: -1,
                isWornUnevenly: false,
                note: ''
            }, {
                axle: 2,
                side: 0,
                id: null,
                make: '',
                model: '',
                width: 0,
                heigth: 0,
                rimDiameter: 0,
                loadClass: '',
                speedCode: '',
                productionTimestamp: '',
                isStudded: false,
                isTwin: false,
                threadDepthOutside: -1,
                threadDepthMiddle: -1,
                threadDepthInside: -1,
                missingStudCount: -1,
                isWornUnevenly: false,
                note: ''
            }, {
                axle: 2,
                side: 1,
                id: null,
                make: '',
                model: '',
                width: 0,
                heigth: 0,
                rimDiameter: 0,
                loadClass: '',
                speedCode: '',
                productionTimestamp: '',
                isStudded: false,
                isTwin: false,
                threadDepthOutside: -1,
                threadDepthMiddle: -1,
                threadDepthInside: -1,
                missingStudCount: -1,
                isWornUnevenly: false,
                note: ''
            }
        ],
        rating: 'bra'
    }

    React.useEffect(() => {
        if (!evaluation) {
            setEvaluation(defaultValues);
        }
    }, [evaluation]);

    const [isCopyFromSet, setIsCopyFromSet] = React.useState(false);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const copyFrom = params.get('copyFrom');

        if (copyFrom) {
            setIsCopyFromSet(true);
            axios.get(`${apiUrl}/admin/evaluations/${copyFrom}`)
                .then(response => {
                    let evaluationData = response.data;

                    // Ensure there are at least 4 tires
                    if (evaluationData.tires.length === 0) {
                        evaluationData.tires.push({
                            axle: 1,
                            side: 0
                        }, {
                            axle: 1,
                            side: 1
                        }, {
                            axle: 2,
                            side: 0
                        }, {
                            axle: 2,
                            side: 1
                        });
                    }
                    setEvaluation(response.data)
                    setTireRating(response.data.rating || 'bra')
                })
                .catch(error => console.error(error));
            // Use the copyFrom value here
        } else {
            setIsCopyFromSet(false);
        }
    }, [location]);

    React.useEffect(() => {
        //console.log(evaluation?.tires);
    }, [evaluation?.tires]);

    const getTirePosition = (axle: number, side: number) => {
        const axlePosition = axle === 1 ? 'foran' : 'bak';
        const sidePosition = side === 0 ? 'Venstre' : 'Høyre';
        return `${sidePosition} ${axlePosition}`;
    };

    const [tireRating, setTireRating] = React.useState<string | null>('bra');
    const TireRatingHelper = () => {
        const formik = useFormikContext<EvaluationsApiResponse>();

        const handleTireRating = (
            event: React.MouseEvent<HTMLElement>,
            newRating: string | null,
        ) => {
            if (newRating === null) {
                return;
            }
            setTireRating(newRating);
            formik.setFieldValue('rating', newRating);
            console.log(`Tire rating: ${newRating}`);
        };

        const handleTextClick = (prompt: string) => {

            console.log(`Removing prompt from note: ${prompt}`);

            if (formik.values.note.includes(prompt)) {
                // Remove the prompt from formik.values.note
                console.log(`Removing prompt from note: ${prompt}`);
                const newNote = formik.values.note.replace(prompt, '');
                formik.setFieldValue('note', newNote);
            } else {
                // Add the prompt to formik.values.note
                console.log(`Adding prompt to note: ${prompt}`);
                formik.setFieldValue('note', formik.values.note + prompt + '\n');
            }
        }

        return <Paper variant='outlined'>
            <Grid container direction="row" mx={1} spacing={0.5}>
                <Grid item xs={12} my={1} direction="row">
                    <ToggleButtonGroup
                        fullWidth
                        size='small'
                        value={tireRating}
                        exclusive
                        onChange={handleTireRating}
                    >
                        <ToggleButton key="bra_key" value="bra" color='success'>
                            <ThumbUpTwoTone />
                            Bra
                        </ToggleButton>
                        <ToggleButton key="middels_key" value="middels" color='warning'>
                            <ThumbUpTwoTone />
                            Middels
                        </ToggleButton>
                        <ToggleButton key="dårlig_key" value="dårlig" color='error'>
                            <ThumbDownTwoTone />
                            Dårlig
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} my={1}>
                    <Typography variant='body1' color="text.primary">
                        {tireRating === 'bra' ? 'Dekkene er vurdert som bra og kan brukes neste sesong' :
                            tireRating === 'middels' ? 'Dekkene er OK, men bør vurderes byttet i løpet av sesongen' :
                                tireRating === 'dårlig' ? 'Dekkene bør byttes før neste sesong' : ''}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FastField
                        component={TextField}
                        fullWidth
                        multiline
                        label="Merknader som skal sendes på SMS og være synlige til kunden"
                        name="note"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <List dense={true}>
                        {helperText.map(text =>
                            <ListItemButton key={text} onClick={() => handleTextClick(text)}>
                                <ListItem key={text}>
                                    <ListItemIcon />
                                    <ListItemText id={text} primary={text} />
                                </ListItem>
                            </ListItemButton>
                        )}
                    </List>
                </Grid>
            </Grid>
        </Paper>;
    }

    const RegNumberHelper = () => {
        const formik = useFormikContext<EvaluationsApiResponse>();
        const [loading, setLoading] = React.useState(false);

        const search = () => {
            console.log('Search for vehicle');
            setLoading(true);
            setErrorMessage(null);

            axios.get(`${apiUrl}/admin/svvdata/${formik.values.vehicle.registrationNumber}`)
                .then(response => {
                    setEvaluation(prevEvaluation => {

                        if (!prevEvaluation) {
                            return; // or return some default state
                        }

                        return {
                            ...prevEvaluation,
                            vehicle: {
                                ...prevEvaluation.vehicle,
                                registrationNumber: formik.values.vehicle.registrationNumber,
                                make: response.data.brand,
                                model: response.data.model
                            }
                        };
                    });

                    if (evaluation) {
                        formik.setValues(evaluation);
                    }

                    setLoading(false);
                }).catch(error => {
                    let errorMessage = 'Søk i SVV sin database feilet (' + apiUrl + ')\n';
                    console.log("Error", error);

                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        errorMessage += `${error.message} ${error.response.data.message}`;
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage += 'Fikk ikke svar fra serveren.';
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        errorMessage += error.message;
                    }

                    setErrorMessage(errorMessage);
                });
        }

        return <Grid item xs={2} sm={2} my={1}>
            <Box sx={{ position: 'relative' }}>
                <Button
                    variant="contained"
                    onClick={search}
                    fullWidth
                >
                    Søk SVV
                </Button>
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
            </Box>
        </Grid>;
    }
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

    const ZeroTireMeasurements = () => {
        const formik = useFormikContext<EvaluationsApiResponse>();
        //console.log('Zeroing tire measurements 0');

        const handleClick = () => {
            //console.log('Zeroing tire measurements 1');
            //console.log(formik);

            setEvaluation(prevEvaluation => {

                if (!prevEvaluation) {
                    return; // or return some default state
                }

                const newTires = prevEvaluation.tires.map(tire => ({
                    ...tire,
                    threadDepthOutside: 0 as number | null,
                    threadDepthMiddle: 0 as number | null,
                    threadDepthInside: 0 as number | null,
                    missingStudCount: 0 as number | null,
                    isWornUnevenly: false,
                    isStudded: false,
                    isTwin: false,
                    note: '',
                }));

                //console.log('Zeroing tire measurements 2');

                return { ...prevEvaluation, tires: newTires };
            });

            if (evaluation) {
                formik.setValues(evaluation);
            }
        };

        return <Button
            fullWidth
            variant='outlined'
            color='warning'
            startIcon={<Delete />}
            onClick={handleClick}>Nullstill alle dekkmålinger</Button>;
    };

    const ZeroTireInformation = () => {
        const formik = useFormikContext<EvaluationsApiResponse>();

        const handleClick = () => {
            setEvaluation(prevEvaluation => {

                if (!prevEvaluation) {
                    return; // or return some default state
                }

                const newTires = prevEvaluation.tires.map(tire => ({
                    ...tire,
                    make: '',
                    model: '',
                    width: 0,
                    heigth: 0,
                    loadClass: '',
                    speedCode: '',
                    productionTimestamp: '',
                    rimDiameter: 0,
                    threadDepthOutside: 0 as number | null,
                    threadDepthMiddle: 0 as number | null,
                    threadDepthInside: 0 as number | null,
                    missingStudCount: 0 as number | null,
                    isWornUnevenly: false,
                    isStudded: false,
                    isTwin: false,
                    note: '',
                }));

                //console.log('Zeroing tire measurements 2');

                return { ...prevEvaluation, tires: newTires };
            });

            if (evaluation) {
                formik.setValues(evaluation);
            }
        };

        return <Button
            fullWidth
            variant='outlined'
            color='error'
            startIcon={<DeleteForever />}
            onClick={handleClick}>Nullstill alle dekkdata</Button>;
    };

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // Define your logic for setting the default season based on the current month
    let defaultSeason;
    if (currentMonth >= 10 && currentMonth <= 12) {
        defaultSeason = `Sommer ${currentYear + 1}`;
    }
    else if (currentMonth >= 10 || currentMonth <= 5) {
        defaultSeason = `Sommer ${currentYear}`;
    } else {
        defaultSeason = `Vinter ${currentYear + 1}`;
    }

    const helperText = ["Mønsterdybde er under 4 mm",
        "Mønsterdybde er under 5 mm",
        "Mønsterdybde er under tillat dybde på 1,6 mm",
        "Mønsterdybde er under tillat dybde på 3 mm",
        "Vi anbefaler deg å bytte 2 dekk",
        "Vi anbefaler deg å bytte 4 dekk",
        "Dekkene er mer enn 7 år gamle",
        "Mer enn 15% av piggene mangler",
        "Skjev slitasje",
        "Trapping i dekket",
        "Dekket har skader",]


    const seasonChoices = ["Sommer 2022", "Sommer 2024", "Sommer 2025", "Sommer 2026", "Sommer 2027", "Sommer 2028", "Sommer 2029", "Sommer 2030",
        "Vinter 2023", "Vinter 2024", "Vinter 2025", "Vinter 2026", "Vinter 2027", "Vinter 2028", "Vinter 2029", "Vinter 2030"] as const;

    return (
        <Grid container justifyContent="flex">
            <Grid item xs={2}>
                <AdminNav />
            </Grid>
            <Grid item xs={10}>
                <Typography variant='h5' color="text.secondary">Dekkvurdering</Typography>

                <Formik
                    //initialValues={evaluation ? evaluation : defaultValues}
                    initialValues={{
                        ...evaluation ? evaluation : defaultValues,
                        season: defaultSeason
                    }}
                    enableReinitialize
                    onSubmit={(evaluation: EvaluationsApiResponse, formikHelpers: FormikHelpers<EvaluationsApiResponse>) => {
                        evaluation.performedDate = new Date().toISOString();
                        evaluation.performedBy = (user ? user.name : 'admin') || '';
                        console.log('Submitting form as ', (user ? user.name : 'admin') || 'user is null')
                        axios.post(`${apiUrl}/admin/evaluations/add/`, evaluation)
                            .then(response => {
                                // handle successful submission here
                                setErrorMessage(null);
                                navigate(-1); // Navigate back
                            })
                            .catch(error => {
                                let errorMessage = 'Lagring feilet (' + apiUrl + ')\n';

                                if (error.response) {
                                    // The request was made and the server responded with a status code
                                    // that falls out of the range of 2xx
                                    errorMessage += `Error: ${error.response.status}. ${error.response.data.message}`;
                                } else if (error.request) {
                                    // The request was made but no response was received
                                    errorMessage += 'Fikk ikke svar fra serveren.';
                                } else {
                                    // Something happened in setting up the request that triggered an Error
                                    errorMessage += error.message;
                                }

                                setErrorMessage(errorMessage);
                            });
                    }}
                >
                    {({ submitForm }) => (
                        <Form>
                            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                            <Typography variant='h6' color="text.primary">Kjøretøy og kunde</Typography>
                            <Grid container spacing={1}>
                                <Grid container direction="row" spacing={2}>
                                    <Grid item xs={10} sm={10} my={1}>
                                        <FastField
                                            fullWidth
                                            key="vehicle.registrationNumber"
                                            size="small"
                                            component={TextField}
                                            type="text"
                                            name="vehicle.registrationNumber"
                                            label="Registreringsnummer"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <RegNumberHelper />
                                </Grid>

                                <Grid item xs={8} sm={8} my={1}>
                                    <Field
                                        formControl={{ fullWidth: true }}
                                        component={Select}
                                        size="small"
                                        id="season"
                                        name="season"
                                        labelId="season-simple"
                                        label="Sesong"
                                    >
                                        {seasonChoices.map((choice, index) => (
                                            <MenuItem key={index} value={choice}>
                                                {choice}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid item xs={4} sm={4} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.location"
                                        size="small"
                                        component={TextField}
                                        name="vehicle.location"
                                        label="Plassering"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.make"
                                        size="small"
                                        component={TextField}
                                        name="vehicle.make"
                                        label="Merke"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.model"
                                        size="small"
                                        component={TextField}
                                        name="vehicle.model"
                                        label="Modell"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.customer.name"
                                        size="small"
                                        component={TextField}
                                        name="vehicle.customer.name"
                                        label="Kunde"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.customer.company"
                                        size="small"
                                        component={TextField}
                                        name="vehicle.customer.company"
                                        label="Firma"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.customer.email"
                                        size="small"
                                        component={TextField}
                                        type="email"
                                        label="E-post"
                                        name="vehicle.customer.email"
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} my={1}>
                                    <FastField
                                        fullWidth
                                        key="vehicle.customer.phone"
                                        size="small"
                                        component={TextField}
                                        label="Telefonnummer"
                                        name="vehicle.customer.phone"
                                    />
                                </Grid>
                            </Grid>
                            <Typography variant='h6' color="text.primary">Dekkvurdering</Typography>
                            <Grid item xs={12} my={2}>
                                <ZeroTireInformation />
                            </Grid>
                            <Grid item xs={12} my={2}>
                                <ZeroTireMeasurements />
                            </Grid>
                            {evaluation && (
                                <TableContainer key='motha' component={Paper}>
                                    <Table key="fucker">
                                        <TableBody key="fotttobar">
                                            {[1, 2].map((axle, axleIndex) => (
                                                <TableRow>
                                                    {[0, 0.5, 1].map((side, sideIndex) => {
                                                        const tireIndex = evaluation?.tires.findIndex(t => (t.axle !== undefined && t.axle === axle) && (t.side !== undefined && t.side === side));
                                                        const tire = tireIndex !== -1 ? evaluation?.tires[tireIndex] : undefined;
                                                        return (
                                                            <TableCell align='center' key={`cell.${tireIndex}`}>
                                                                {axleIndex === 0 && sideIndex === 1 && (
                                                                    <Grid alignContent='center'>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <CopyTire from={TirePosition.FrontLeft} to={TirePosition.FrontRight} />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} my={2}>
                                                                            <CopyTire from={TirePosition.Front} to={TirePosition.Rear} />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} my={2}>
                                                                            <CopyTire from={TirePosition.FrontLeft} to={TirePosition.All} />
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12} my={2}>
                                                                            <Button
                                                                                fullWidth
                                                                                variant='contained'
                                                                                color='success'
                                                                                onClick={submitForm}
                                                                                startIcon={<SaveTwoTone />}>Lagre</Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {axleIndex === 1 && sideIndex === 1 && (
                                                                    <Grid alignContent='center'>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <CopyTire from={TirePosition.RearLeft} to={TirePosition.RearRight} />
                                                                        </Grid>
                                                                    </Grid>
                                                                )}
                                                                {tire && (
                                                                    <div>
                                                                        <StyledBox>
                                                                            <Box marginBottom={2}>
                                                                                <Typography variant='subtitle1'>{getTirePosition(tire.axle, tire.side)}</Typography>
                                                                            </Box>
                                                                            <Box marginBottom={2}>
                                                                                <FastField
                                                                                    key={`tires.${tireIndex}.make`}
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    component={TextField}
                                                                                    label="Merke"
                                                                                    name={`tires[${tireIndex}].make`}
                                                                                />
                                                                            </Box>
                                                                            <Box marginBottom={2}>
                                                                                <FastField
                                                                                    fullWidth
                                                                                    size="small"
                                                                                    component={TextField}
                                                                                    label="Modell"
                                                                                    name={`tires[${tireIndex}].model`}
                                                                                    key={`tires.${tireIndex}.model`}
                                                                                />
                                                                            </Box>

                                                                            <Grid container direction="row" spacing={2}>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <FastField
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        type="number"
                                                                                        name={`tires[${tireIndex}].width`}
                                                                                        key={`tires.${tireIndex}.width`}
                                                                                        label="Bredde"
                                                                                        variant="outlined"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <FastField
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        type="number"
                                                                                        name={`tires[${tireIndex}].heigth`}
                                                                                        key={`tires.${tireIndex}.heigth`}
                                                                                        label="Profil"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <FastField
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        type="number"
                                                                                        name={`tires[${tireIndex}].rimDiameter`}
                                                                                        key={`tires.${tireIndex}.rimDiameter`}
                                                                                        label="Diameter"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>

                                                                            <Grid container direction="row" spacing={2} marginY={0.1}>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].loadClass`}
                                                                                        key={`tires.${tireIndex}.loadClass`}
                                                                                        label="Last"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].speedCode`}
                                                                                        key={`tires.${tireIndex}.speedCode`}
                                                                                        label="Hastighet"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].productionTimestamp`}
                                                                                        label="Produsert (uke/år)"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container direction="row">
                                                                                <Grid item xs={6} sm={6} my={2}>
                                                                                    <Field
                                                                                        component={CheckboxWithLabel}
                                                                                        type="checkbox"
                                                                                        size="small"
                                                                                        Label={{ label: 'Piggdekk' }}
                                                                                        name={`tires[${tireIndex}].isStudded`}
                                                                                        key={`tires.${tireIndex}.isStudded`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={6} sm={6} my={2}>
                                                                                    <Field
                                                                                        component={CheckboxWithLabel}
                                                                                        size="small"
                                                                                        type="checkbox"
                                                                                        Label={{ label: 'C-Dekk' }}
                                                                                        name={`tires[${tireIndex}].isTwin`}
                                                                                        key={`tires.${tireIndex}.isTwin`}
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Box marginBottom={2}>
                                                                                <Typography variant='subtitle2'>Dekkmålinger</Typography>
                                                                            </Box>
                                                                            <Grid container direction="row">
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        type="number"
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].threadDepthOutside`}
                                                                                        key={`tires.${tireIndex}.threadDepthOutside`}
                                                                                        label="U (mm)"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        type="number"
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].threadDepthMiddle`}
                                                                                        key={`tires.${tireIndex}.threadDepthMiddle`}
                                                                                        label="M (mm)"
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        type="number"
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].threadDepthInside`}
                                                                                        key={`tires.${tireIndex}.threadDepthInside`}
                                                                                        label="I (mm)"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container direction="row">
                                                                                <Grid item xs={8} sm={8} my={2}>
                                                                                    <Field
                                                                                        component={CheckboxWithLabel}
                                                                                        size="small"
                                                                                        type="checkbox"
                                                                                        Label={{ label: 'Skjev slitasje' }}
                                                                                        name={`tires[${tireIndex}].isWornUnevenly`}
                                                                                        key={`tires.${tireIndex}.isWornUnevenly`}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4} sm={4} my={2}>
                                                                                    <Field
                                                                                        fullWidth
                                                                                        component={TextField}
                                                                                        type="number"
                                                                                        size="small"
                                                                                        name={`tires[${tireIndex}].missingStuds`}
                                                                                        key={`tires.${tireIndex}.missingStuds`}
                                                                                        label="Manglende pigger"
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </StyledBox>
                                                                    </div>
                                                                )}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )}

                            <TireRatingHelper />
                            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
                            <Button
                                fullWidth
                                variant='contained'
                                color='success'
                                onClick={submitForm}
                                startIcon={<SaveTwoTone />}>Lagre</Button>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid >
    );
}
