import React, { useState } from 'react';
import {
  Typography,
  Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Link, Button
} from '@mui/material';
import { AddOutlined, DeleteForever, MoreHorizOutlined, ThumbDownTwoTone, ThumbUpTwoTone } from '@mui/icons-material';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import { useTheme } from '@mui/material/styles';
import axios from 'axios';
import { apiUrl } from '../../config';
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse';
import { format, parseISO } from 'date-fns';
import { nb } from 'date-fns/locale';
import ErrorMessage from '../../shared-components/ErrorMessage';
interface TireRatingTableProps {
  evaluations: EvaluationsApiResponse[];
  searchInput?: string;
  onDeleteEvaluation: (id: number) => void;
}

const SmsStatusTable: React.FC<TireRatingTableProps> = ({ evaluations, searchInput = '', onDeleteEvaluation }) => {
  const theme = useTheme();

  const [error, setError] = useState<string | null>(null); // State for error message

  const handleDeleteEvaluation = (id: number) => {

    if (window.confirm('Vil du slette denne vurderingen?')) {
      axios.delete(`${apiUrl}/admin/evaluations/delete/${id}`)
        .then(() => {
          onDeleteEvaluation(id);
        })
        .catch(error => {
          let errorMessage = 'Sletting feilet (' + apiUrl + ')\n';

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            errorMessage += `Error: ${error.response.status}. ${error.response.data.message}`;
          } else if (error.request) {
            // The request was made but no response was received
            errorMessage += 'Fikk ikke svar fra serveren.';
          } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage += error.message;
            setError(errorMessage); // Set the error message in the state
          }
        });
    } else {
      // User clicked Cancel
      // Do nothing
    }
  };

  return (
    <Grid item xs={12} padding={1}>
      <div>
        {error && <ErrorMessage message={error} />}
      </div>
      <Typography variant='body1' color="text.secondary">
        {`Antall vurderinger: ${evaluations.length}`}
      </Typography>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 600 }} component={Paper}>
          <Table stickyHeader aria-label="tabell dekkvurderinger">
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Eier</TableCell>
                <TableCell>Telefon</TableCell>
                <TableCell>Regnummer</TableCell>
                <TableCell>Plassering</TableCell>
                <TableCell>SMS Status</TableCell>
                <TableCell>Vurdering</TableCell>
                <TableCell>Detaljer</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {evaluations.map((evaluation) => (
                <TableRow key={evaluation.id}>
                  <TableCell>{evaluation.id}</TableCell>
                  <TableCell>
                    {evaluation.vehicle.customer.company
                      ? `${evaluation.vehicle.customer.company}${evaluation.vehicle.customer.name ? ` (${evaluation.vehicle.customer.name})` : ''}`
                      : evaluation.vehicle.customer.name}
                  </TableCell>
                  <TableCell>
                    <Link href={`tel:${evaluation.vehicle.customer.phone}`}>
                      {evaluation.vehicle.customer.phone}
                    </Link>
                  </TableCell>
                  <TableCell>{evaluation.vehicle.registrationNumber}</TableCell>
                  <TableCell>{evaluation.vehicle.location}</TableCell>
                  <TableCell>
                    {evaluation.smsLogEntries && evaluation.smsLogEntries.length > 0 ? (
                      evaluation.smsLogEntries[0].hasBeenSent && evaluation.smsLogEntries[0].statusTimestamp ? (
                        `Sendt ${format(evaluation.smsLogEntries[0].statusTimestamp, 'dd/MM/yyyy HH:mm:ss', { locale: nb })}`
                      ) : (
                        evaluation.smsLogEntries[0].errorDescription ? (
                          evaluation.smsLogEntries[0].errorDescription
                        ) : (
                          ''
                        )
                      )
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {evaluation.rating === 'bra' ? <ThumbUpTwoTone style={{ color: theme.palette.success.main }} /> :
                      evaluation.rating === 'middels' ? <ThumbUpTwoTone style={{ color: theme.palette.warning.main }} /> :
                        <ThumbDownTwoTone style={{ color: theme.palette.error.main }} />
                    }
                  </TableCell>
                  <TableCell align='center'>
                    <Link href={`/admin/auth/tirerating/${evaluation.id}/`}>
                      <MoreHorizOutlined />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}

export default SmsStatusTable;