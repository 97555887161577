// AdminNav.tsx
import { Link } from 'react-router-dom'
import { List, ListItemButton, ListItemIcon, ListItemText, Grid, useTheme, useMediaQuery, Tooltip } from '@mui/material'
import { TireRepairTwoTone, Add, SearchTwoTone, TextsmsTwoTone, TimeToLeaveTwoTone, WatchTwoTone, LockClockTwoTone, Timer10SelectTwoTone, TimerTwoTone, DetailsTwoTone, MoreTwoTone } from '@mui/icons-material'
import { withAuthenticationRequired } from '@auth0/auth0-react'

export const AdminNav = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Grid item sm={1} md={2}>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <Tooltip title="Vurderinger pr. sesong">
          <ListItemButton component={Link} to="/admin/auth/tirerating/overview">
            <ListItemIcon>
              <TireRepairTwoTone />
            </ListItemIcon>
            {matches && <ListItemText primary="Vurderinger" />}
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Søk i alle vurderinger">
          <ListItemButton component={Link} to="/admin/auth/tirerating/search" sx={{ pl: 4 }}>
            <ListItemIcon>
              <SearchTwoTone />
            </ListItemIcon>
            {matches && <ListItemText primary="Søk" />}
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Registrer ny vurdering">
          <ListItemButton component={Link} to="/admin/auth/tirerating/add"sx={{ pl: 4 }}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            {matches && <ListItemText primary="Registrer" />}
          </ListItemButton>
        </Tooltip>        
        <Tooltip title="SMS håndtering">
          <ListItemButton component={Link} to="/admin/auth/tirerating/sms"sx={{ pl: 4 }}>
            <ListItemIcon>
              <TextsmsTwoTone />
            </ListItemIcon>
            {matches && <ListItemText primary="SMS" />}
          </ListItemButton>
        </Tooltip>

        <Tooltip title="Timebestillinger">
          <ListItemButton component={Link} to="/admin/auth/appointments/overview">
            <ListItemIcon>
              <TimerTwoTone />
            </ListItemIcon>
            {matches && <ListItemText primary="Timebestillinger" />}
          </ListItemButton>
        </Tooltip>
        <Tooltip title="Oppsett">
          <ListItemButton component={Link} to="/admin/auth/appointments/config" sx={{ pl: 4 }}>
            <ListItemIcon>
              <MoreTwoTone />
            </ListItemIcon>
            {matches && <ListItemText primary="Oppsett" />}
          </ListItemButton>
        </Tooltip>        
      </List>
    </Grid>
  )
} 

