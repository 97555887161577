import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recaptchaSite } from '../config';

declare global {
    interface Window {
        grecaptcha: any;
    }
}

const TireRatingLogin = () => {
    const [telefonnummer, setTelefonnummer] = useState('');
    const [registreringsnummer, setRegistreringsnummer] = useState('');
    const [recaptchaOk, setRecaptchaOk] = useState(false);
    const recaptchaRef = useRef(null);

    useEffect(() => {
        const interval = setInterval(() => {
            if (window.grecaptcha && recaptchaRef.current) {
                window.grecaptcha.render(recaptchaRef.current, {
                    sitekey: recaptchaSite,
                    callback: verifyCallback,
                });
                clearInterval(interval);
            }
        }, 100);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleTelefonnummerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelefonnummer(event.target.value);
    };
    const navigate = useNavigate();
    const handleRegistreringsnummerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRegistreringsnummer(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        navigate(`/dekkvurdering/${telefonnummer}/${registreringsnummer}`);
    };
    type VerifyCallback = (response: string) => void;

    const verifyCallback: VerifyCallback = (response) => {
        setRecaptchaOk(true);
    };
    (window as any).verifyCallback = verifyCallback;

    return (
        <Grid container justifyContent="center" >
            <form onSubmit={handleSubmit}>
                <Grid item xs={12} sm={10}>
                    <Card>
                        <CardContent >
                            <Typography variant='h4' color="text.secondary" gutterBottom>
                                Logg inn for å se vurdering av dine dekk
                            </Typography>

                            <Typography variant="body2" margin={1}>
                                Skriv inn ditt telefonnummer (8 siffer) og kjøretøyets registreringsnummer, og verifiser at du ikke er en robot.
                            </Typography>

                            <TextField margin='normal'
                                label="Telefonnummer"
                                value={telefonnummer}
                                onChange={handleTelefonnummerChange}
                                inputProps={{ maxLength: 8 }}
                                required
                            />
                            <TextField
                                margin='normal'
                                label="Registreringsnummer"
                                value={registreringsnummer}
                                onChange={handleRegistreringsnummerChange}
                                inputProps={{ maxLength: 9 }}
                                required
                            />
                            <div>
                                {recaptchaOk ? (
                                    <Button type="submit" variant="contained" color="primary">
                                        Se vurdering
                                    </Button>
                                ) : (
                                    <div ref={recaptchaRef}></div>
                                    // <div className="g-recaptcha" data-sitekey={recaptchaSite} data-callback="verifyCallback"></div>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </form>
        </Grid>
    );
};

export default TireRatingLogin;