import React, { useState, useEffect } from 'react'
import {
  FormControl, SelectChangeEvent, Typography,
  Grid, TextField, Box, LinearProgress
} from '@mui/material'
import axios from 'axios'
import { Vehicle } from '../../interfaces/Vehicle'
import { Tire } from '../../interfaces/Tire'
import { AdminNav } from '../AdminNav'
import { useTheme } from '@mui/material/styles'
import TireRatingTable from './TireRatingTable'
import { apiUrl } from '../../config'
import { SmsLogEntry } from '../../interfaces/SmsLogEntry'
import ErrorMessage from '../../shared-components/ErrorMessage'

interface EvaluationsApiResponse {
  id: number
  season: string
  performedBy: string
  performedDate: string
  vehicle: Vehicle
  note: string
  rating: string
  tires: Tire[],
  smsLogEntries: SmsLogEntry[]
}

function TireRatingSearch() {
  const [seasons, setSeasons] = useState<string[]>([])
  const [evaluations, setEvaluations] = useState<EvaluationsApiResponse[]>([])
  const theme = useTheme()
  const [searchInput, setSearchInput] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const [selectedSeason, setSelectedSeason] = useState(() => {
    const savedSeason = localStorage.getItem('selectedSeason')
    return savedSeason ? savedSeason : ''
  })

  const [error, setError] = useState<string | null>(null) // State for error message

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value)
  }

  useEffect(() => {
    console.log('Searching', isSearching)
  }, [isSearching])

  const searchEvaluations = (searchTerm: string) => {

    setIsSearching(true)

    axios.get(`${apiUrl}/admin/evaluations/search/${searchTerm}`)
      .then(response => {
        setEvaluations(response.data)
        setIsSearching(false)
      })
      .catch(error => {
        console.error(error)
        setIsSearching(false)
        setError(error.message) // Set the error message in the state
      })
  }

  useEffect(() => {
    if (searchInput.length >= 3) {
      const timeoutId = setTimeout(() => {
        searchEvaluations(searchInput)
      }, 500) // 500ms delay

      return () => clearTimeout(timeoutId) // clear the timeout if the input changes before the delay is over
    }
  }, [searchInput, selectedSeason])

  useEffect(() => {
    axios.get(`${apiUrl}/admin/evaluations/seasons/`)
      .then(response => setSeasons(response.data))
      .catch(error => {
        console.error(error)
        setError(error.message) // Set the error message in the state
      })

  }, [])
  useEffect(() => {
    localStorage.setItem('selectedSeason', selectedSeason)
  }, [selectedSeason])
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedSeason(event.target.value as string)
  }
  const handleDeleteEvaluation = (id: number) => {
    setEvaluations(prevEvaluations => prevEvaluations.filter(evaluation => evaluation.id !== id))
  }

  return (
    <Grid container justifyContent="flex">
      <Grid item xs={2}>
        <AdminNav />
      </Grid>
      <Grid item xs={10}>
        <div>
          {error && <ErrorMessage message={error} />}
        </div>
        <Typography variant='h5' color="text.secondary">Dekkvurderinger</Typography>
        <Typography variant='body1' color="text.primary">Bruk søkefeltet til å søke i alle vurderinger</Typography>
        <Grid item xs={11} m={0.5}>
          <Box position="relative" display="inline-flex" width="100%">
            <FormControl fullWidth>
              <TextField
                id="search-input"
                label="Filter (minst 3 tegn)"
                value={searchInput}
                onChange={handleSearchChange}
              />
            </FormControl>
            {isSearching && (
              <Box position="absolute" bottom={0} left={0} right={0}>
                <LinearProgress color='warning' />
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} padding={1}>
          <TireRatingTable evaluations={evaluations} searchInput={searchInput} onDeleteEvaluation={handleDeleteEvaluation} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TireRatingSearch