import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card, CardContent, TextField, Grid, Alert, CircularProgress, CardHeader } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Tire } from '../interfaces/Tire';
import TireList from '../shared-components/TireList';
import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import InfoRow from '../shared-components/InfoRow';
import { apiUrl } from '../config';
import { ThumbDownTwoTone, ThumbUpTwoTone } from '@mui/icons-material';

interface ApiResponse {
    id: number;
    season: string;
    performedBy: string;
    performedDate: string;
    registrationNumber: string;
    make: string;
    model: string;
    note: string;
    rating: string;
    tires: Tire[];
}

const TireRating: React.FC = () => {
    const { telefonnummer, registreringsnummer } = useParams<{ telefonnummer: string; registreringsnummer: string }>();
    const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before starting the API call
            setError(null); // Reset the error message before starting the API call
            let response;
            try {
                response = await axios.get<ApiResponse>(`${apiUrl}/admin/customers/${telefonnummer}/${registreringsnummer}`);
                setApiResponse(response.data);
            } catch (error) {
                console.error(error);
                setError('Vi klarte ikke å hente dekkvurderingen din akkurat nå, sjekk at telefonnummer og registreringsnummer er riktig');

            } finally {
                setLoading(false); // Set loading to false after the API call is finished
            }
        };

        fetchData();
    }, [telefonnummer, registreringsnummer]);

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={8}>
                {loading && <CircularProgress />} {/* Display the loading spinner */}
                {error && <Alert severity="error">{error}</Alert>} {/* Display the error message */}
                {apiResponse && (
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar style={{
                                    backgroundColor:
                                        apiResponse.rating === 'bra' ? theme.palette.success.main :
                                            apiResponse.rating === 'middels' ? theme.palette.warning.main :
                                                theme.palette.error.main
                                }}>
                                    {apiResponse.rating === 'bra' || apiResponse.rating === 'middels' ? <ThumbUpTwoTone /> : <ThumbDownTwoTone />}
                                </Avatar>
                            }
                            title="Dekkvurdering"
                            titleTypographyProps={{ variant: 'h4', color: "text.secondary" }}
                        />
                        <CardContent>
                            {apiResponse.rating === 'bra' ?
                                <Alert severity="success">Dekkene dine er vurdert som bra og kan trygt brukes neste sesong</Alert> :
                                apiResponse.rating === 'middels' ?
                                    <Alert severity="warning">Dekkene dine er vurdert som middels bra og bør skiftes i løpet av sesongen</Alert> :
                                    <Alert severity="error">Dekkene dine er vurdert som dårlig og bør skiftes</Alert>
                            }
                            <InfoRow label='Kjøretøy' value={`${apiResponse.make} ${apiResponse.model} (${apiResponse.registrationNumber})`} />
                            <InfoRow label='Dato' value={`${new Date(apiResponse.performedDate).toLocaleDateString('nb-NO')}`} />
                            <TextField contentEditable='false' style={{ marginTop:10, padding: 10 }} fullWidth multiline label='Kommentar' value={apiResponse.note} />
                            <TireList tires={apiResponse.tires} />
                        </CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
};

export default TireRating;