import { Grid, Typography } from "@mui/material";

interface InfoRowProps {
    label: string;
    value: string;
  }

  const InfoRow: React.FC<InfoRowProps> = ({ label, value }) => {
    return (
      <Grid container direction="row" alignItems="center">
        <Grid item lg={1} md={2} sm={6} xs={12}>
          <Typography variant='body1'>{label}: </Typography>
        </Grid>
        <Grid item marginLeft={2} md={6} sm={6} xs={8}>
          <Typography variant='body2' sx={{ fontFamily: 'monospace' }}>{value}</Typography>
        </Grid>
      </Grid>
    );
  };

  export default InfoRow;