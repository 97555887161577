import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface SmsConfirmationDialogProps {
  open: boolean;
  title: string;
  message: string;
  onConfirm: () => void;
  onConfirmTest: () => void;
  onCancel: () => void;
}

const SmsConfirmationDialog: React.FC<SmsConfirmationDialogProps> = ({ open, title, message, onConfirm, onConfirmTest, onCancel }) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="warning" variant='outlined'>
          Avbryt
        </Button>
        <Button onClick={onConfirmTest} color="success" autoFocus variant='outlined'>
          Send TEST-SMS
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus variant='outlined'>
          Send SMS
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SmsConfirmationDialog;