import React, { useState, useEffect } from 'react';
import {
  Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Typography, Grid, LinearProgress, Box, Paper, Button, Alert
} from '@mui/material';

import axios from 'axios';
import { AdminNav } from '../AdminNav';
import { useTheme } from '@mui/material/styles';
import { EvaluationsApiResponse } from '../../interfaces/EvaluationApiResponse';
import { apiUrl } from '../../config';
import SmsStatusTable from './SmsStatusTable';
import { SmsOutlined, ThumbDownTwoTone } from '@mui/icons-material';
import SmsConfirmationDialog from '../../shared-components/SmsConfirmationDialog';
import ErrorMessage from '../../shared-components/ErrorMessage';

function TireRatingSms() {
  const [seasons, setSeasons] = useState<string[]>([]);
  const [evaluations, setEvaluations] = useState<EvaluationsApiResponse[]>([]);
  const theme = useTheme();
  const [searchInput, setSearchInput] = useState('');
  const [selectedSeason, setSelectedSeason] = useState(() => {
    const savedSeason = localStorage.getItem('selectedSeason');
    return savedSeason ? savedSeason : '';
  });

  const handleDeleteEvaluation = (id: number | null) => {
    setEvaluations(prevEvaluations => prevEvaluations.filter(evaluation => evaluation.id !== id));
  };

  const searchEvaluations = (searchTerm: string, selectedSeason: string) => {
    axios.get(`${apiUrl}/admin/evaluations/search/${selectedSeason}/${searchTerm}`)
      .then(response => setEvaluations(response.data))
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };

  const evaluationsForSeason = (season: string) => {
    axios.get(`${apiUrl}/admin/evaluations/season/${season}`)
      .then(response => setEvaluations(response.data))
      .catch(error => {
        console.error(error);
        setErrorMessage(error.message);
      });
  };

  useEffect(() => {
    if (selectedSeason) {
      evaluationsForSeason(selectedSeason);
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (searchInput.length >= 3) {
      const timeoutId = setTimeout(() => {
        searchEvaluations(searchInput, selectedSeason);
      }, 500); // 500ms delay

      return () => clearTimeout(timeoutId); // clear the timeout if the input changes before the delay is over
    }
    else if (searchInput.length === 0 && selectedSeason) {
      evaluationsForSeason(selectedSeason);
    }
  }, [searchInput, selectedSeason]);

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
    axios.get(`${apiUrl}/admin/evaluations/seasons/`)
      .then(response => {
        setSeasons(response.data);
        setIsSearching(false);
      })
      .catch(error => {
        setIsSearching(false);
        console.error(error);
        setErrorMessage(error.message);
      });
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedSeason', selectedSeason);
  }, [selectedSeason]);
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedSeason(event.target.value as string);
  };

  const [open, setOpen] = useState(false);
  const [openMedium, setOpenMedium] = useState(false);
  const [openBad, setOpenBad] = useState(false);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setErrorMessage(null);
    setOpen(true);
  };

  const handleClickOpenMedium = () => {
    setErrorMessage(null);
    setOpenMedium(true);
  };

  const handleClickOpenBad = () => {
    setErrorMessage(null);
    setOpenBad(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenMedium(false);
    setOpenBad(false);
  };

  const handleConfirmTestSendAll = () => {
    handleClose();

    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/alle-test`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved test-sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved test-sending av SMS: ' + error.message);
          console.error(error)
        }
      });
  };

  const handleConfirmSendAll = () => {
    handleClose();
    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/alle`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved sending av SMS: ' + error.message);
          console.error(error)
        }
      });
  };

  const handleConfirmTestSendBadRating = () => {
    handleClose();

    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/dårlig-test`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved test-sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved test-sending av SMS: ' + error.message);
          console.error(error)
        }
        
      });
  };

  const handleConfirmSendBadRating = () => {
    handleClose();
    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/dårlig`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved sending av SMS: ' + error.message);
          console.error(error)
        }
      });
  };
  const handleConfirmTestSendMediumRating = () => {
    handleClose();
    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/middels-test`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved test-sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved test-sending av SMS: ' + error.message);
          console.error(error)
        }
      });
  };

  const handleConfirmSendMediumRating = () => {
    handleClose();

    axios.get(`${apiUrl}/admin/evaluations/sms/${selectedSeason}/middels`)
      .catch(error => {
        if (error.response.data) {
          console.error(error.response.data)
          setErrorMessage('Feil ved sending av SMS: ' + error.response.data);
        }
        else {
          setErrorMessage('Feil ved sending av SMS: ' + error.message);
          console.error(error)
        }
      });
  };

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  return (
    <Grid container justifyContent="flex">
      <Grid item xs={2}>
        <AdminNav />
      </Grid>
      <Grid item xs={10}>
        <Typography variant='h5' color="text.secondary">SMS Sending og status</Typography>
        <Typography variant='body1' color="text.primary">Velg sesong for å filtrere oversikten</Typography>
        <Grid container item direction="row" justifyContent="space-between">
          <Grid item xs={10} sm={10} md={5} mx={1} my={1}>
            <Box position="relative" display="inline-flex" width="100%">
              <FormControl fullWidth>
                <InputLabel id="season-label">Sesong</InputLabel>
                <Select
                  labelId="season-label"
                  value={selectedSeason}
                  onChange={handleChange}
                  title='Sesong'
                >
                  {seasons.map((season, index) => (
                    <MenuItem key={index} value={season}>{season}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {isSearching && (
                <Box position="absolute" bottom={0} left={0} right={0}>
                  <LinearProgress color='warning' />
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
        <div>
          {errorMessage && ErrorMessage({ message: errorMessage })}
        </div>

        <Grid container item direction="row" justifyContent="space-between">
          <Grid item xs={12} sm={12} md={3.5} my={1} mx={1}>
            <Paper square={false} variant='outlined'>
              <Grid container direction={'column'} spacing={1} mx={1}>
                <Grid item xs={12}>
                  <Typography variant='h6' color="text.secondary">Send SMS til alle</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color="text.secondary">Sender SMS til alle som ikke har mottatt SMS tidligere</Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                  <Button endIcon={<SmsOutlined />} color='primary' onClick={handleClickOpen} size="small" variant='outlined'>
                    Send til alle
                  </Button>
                  <SmsConfirmationDialog
                    open={open}
                    title="Send SMS til alle"
                    message={`Er du sikker på at du vil sende SMS til alle i ${selectedSeason}, som ikke har mottatt SMS allerede?`}
                    onConfirm={handleConfirmSendAll}
                    onConfirmTest={handleConfirmTestSendAll}
                    onCancel={handleClose} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={4} my={1} mx={1}>
            <Paper square={false} variant='outlined'>
              <Grid container direction={'column'} spacing={1} mx={1}>
                <Grid item xs={12}>
                  <Typography variant='h6' color="text.secondary">Send SMS til alle med middels vurdering</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color="text.secondary">Sender SMS til alle som har middels god dekkvurdering</Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                  <Button endIcon={<ThumbDownTwoTone />} color='warning' onClick={handleClickOpenMedium} size="small" variant='outlined'>
                    Middels vurdering
                  </Button>
                  <SmsConfirmationDialog
                    open={openMedium}
                    title="Send SMS til alle med middels dekkvurdering"
                    message={`Er du sikker på at du vil sende SMS til alle med middels dekkvurdering i ${selectedSeason}, som ikke har mottatt SMS allerede?`}
                    onConfirm={handleConfirmSendMediumRating}
                    onConfirmTest={handleConfirmTestSendMediumRating}
                    onCancel={handleClose} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={12} md={3.5} my={1} mx={1}>
            <Paper square={false} variant='outlined'>
              <Grid container direction={'column'} spacing={1} mx={1}>
                <Grid item xs={12}>
                  <Typography variant='h6' color="text.secondary">Send SMS til alle med dårlig vurdering</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' color="text.secondary">Sender SMS til alle som har dårlig dekkvurdering</Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                  <Button endIcon={<ThumbDownTwoTone />} color='error' onClick={handleClickOpenBad} size="small" variant='outlined'>
                    Dårlig vurdering
                  </Button>
                  <SmsConfirmationDialog
                    open={openBad}
                    title="Send SMS til alle med dårlig dekkvurdering"
                    message={`Er du sikker på at du vil sende SMS til alle med dårlig dekkvurdering i ${selectedSeason}, som ikke har mottatt SMS allerede?`}
                    onConfirm={handleConfirmSendBadRating}
                    onConfirmTest={handleConfirmTestSendBadRating}
                    onCancel={handleClose} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} padding={1}>
          <SmsStatusTable evaluations={evaluations} searchInput={searchInput} onDeleteEvaluation={handleDeleteEvaluation} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TireRatingSms;