import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, useAuth0, User, AppState } from '@auth0/auth0-react';
import { AdminNav } from './AdminNav';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/admin/auth');
  };
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated, user } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/admin/auth",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };

  return (
    <Grid container justifyContent="center" >

      {!isAuthenticated && (
        <Grid item xs={12} sm={8}>
          <Card>
            <CardContent>
              <Button type='button' onClick={() => handleLogin()}>Logg inn</Button>
            </CardContent>
          </Card>
        </Grid>
      )
      }
      {
        isAuthenticated && (
          <>

            <Grid item xs={2} sm={2}>
              <AdminNav />
            </Grid>
            <Grid item xs={10} sm={10}>
              {

              }
            </Grid>
          </>
        )
      }
    </Grid >
  );
};

export default AdminLogin;