import React, { ReactNode } from 'react';
import MainAppBar from './MainAppBar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Box from '@mui/material/Box';
interface LayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <MainAppBar />
      <Box mt={2}> {/* This Box component adds a margin-top of 2 units */}
        <main>
          {children}
        </main>
      </Box>
    </>
  );
};

export default MainLayout;